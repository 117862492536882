<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <!--      <a-button v-action:add type="primary" icon="plus" @click="handleAdd">新增</a-button>-->
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="shipper_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
    >


      <div slot="vehicle_name" slot-scope="text, item">
        <b>{{ text }}</b>
      </div>
      <div slot="tool_list" slot-scope="text, item">
        <div v-for="i in item.tool_list">
          {{ i.tool_name }}(<span style="color: #a60000">￥</span>{{ i.price }}/{{ i.tool_unit }}) x <a>{{
            i.number
          }}</a>
        </div>
      </div>
      <div slot="source" slot-scope="text, item">
        {{ text == 1 ? '后台' : '用户' }}
      </div>
      <div slot="total_amount" slot-scope="text, item">
        <span style="color: #d00505">¥</span>{{ text }}
      </div>

      <div slot="status" slot-scope="text, item">
        <a-tag v-if="text==0">待审核</a-tag>
        <a-tag color="green" v-if="text==1">审核通过</a-tag>
        <template v-if="text==2">
          <a-tag color="red">审核驳回</a-tag>
          <div v-if="item.remarks" style="color: red">驳回原因：{{ item.remarks }}</div>
        </template>
      </div>

      <div class="actions" slot="action" slot-scope="text, item">
        <template v-if="item.status==0">
          <a v-action:audit @click="onAuditPass(item)">审核通过</a>
          <a v-action:audit @click="onAuditReject(item)">审核驳回</a>
        </template>
      </div>
    </s-table>
    <AuditForm ref="AuditForm" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/tool/toolApprove'
import {STable} from '@/components'
import AuditForm from './modules/AuditForm.vue'
import SearchForm from './modules/SearchForm.vue'

export default {
  name: 'Index',
  components: {
    STable,
    AuditForm,
    SearchForm
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: '申领ID',
        //   dataIndex: 'approve_id',
        //   align: 'center',
        //   width: 60,
        // },
        {
          title: '车辆号码',
          dataIndex: 'vehicle.vehicle_name',
          align: 'center',
          scopedSlots: {customRender: 'vehicle_name'}
        },
        {
          title: '申领工具',
          dataIndex: 'tool_list',
          align: 'center',
          scopedSlots: {customRender: 'tool_list'}
        },
        {
          title: '总金额',
          dataIndex: 'total_amount',
          align: 'center',
          scopedSlots: {customRender: 'total_amount'}
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '发放人',
          dataIndex: 'audit_storeuser.real_name',
          align: 'center',
        },
        {
          title: '发放时间',
          dataIndex: 'audit_time',
          align: 'center',
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        // {
        //   title: '来源',
        //   dataIndex: 'source',
        //   align: 'center',
        //   scopedSlots: {customRender: 'source'},
        //   width: 60,
        // },

        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    async onAuditPass(item) {
      await Api.edit({approve_id: item.approve_id, form: {status:1,audit:1}})
      this.$message.success('操作成功', 1.5)
      this.handleRefresh()
    },
    onAuditReject(item) {
      this.$refs.AuditForm.edit(item)
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
